/* eslint-disable */
import React, { useEffect, useState } from 'react'
import './page_index.less'
import config from 'src/config/index'
import Layout from 'src/components/layout/LayoutPure'
import Navbar from 'src/components/sections/navbar'
import Preface from 'src/components/sections/preface'
import Portfolio from 'src/components/sections/portfolio'
import Team from 'src/components/sections/team'
import Footer from 'src/components/sections/footer'
// import ContactForm from 'src/components/contactForm'
import WeAlso from '../components/sections/weAlso'

const IndexPage = ({ language = 'en' }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    const loadScript = (url, onLoad) => {
      var script = document.createElement('script')
      script.onload = function() {
        onLoad()
        // alert("Script loaded and ready");
      }
      script.src = url
      document.getElementsByTagName('head')[0].appendChild(script)
    }

    loadScript(
      'https://cdnjs.cloudflare.com/ajax/libs/three.js/96/three.min.js',
      () =>
        loadScript(
          'https://cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/TweenMax.min.js',
          () => {
            window.hoverEffect = function(a) {
              var b = a.parent || console.warn('no parent'),
                g =
                  a.displacementImage ||
                  console.warn('displacement image missing'),
                e = a.image1 || console.warn('first image missing'),
                f = a.image2 || console.warn('second image missing'),
                d = a.intensity || 1,
                l = a.speedIn || 1.6,
                m = a.speedOut || 1.2,
                r = void 0 === a.hover ? !0 : a.hover,
                k = a.easing || window.Expo.easeOut,
                t = function() {
                  var a = !1,
                    b = navigator.userAgent || navigator.vendor || window.opera
                  if (
                    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                      b
                    ) ||
                    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                      b.substr(0, 4)
                    )
                  )
                    a = !0
                  return a
                },
                n = new window.THREE.Scene(),
                p = new window.THREE.OrthographicCamera(
                  b.offsetWidth / -2,
                  b.offsetWidth / 2,
                  b.offsetHeight / 2,
                  b.offsetHeight / -2,
                  1,
                  1e3
                )
              p.position.z = 1
              var c = new window.THREE.WebGLRenderer({ antialias: !1 })
              // c.context.canvas.loseContextInNCalls(100);
              c.setPixelRatio(window.devicePixelRatio)
              c.setClearColor(16777215, 0)
              c.setSize(b.offsetWidth, b.offsetHeight)
              b.appendChild(c.domElement)
              a = new window.THREE.TextureLoader()
              a.crossOrigin = ''
              e = a.load(e)
              f = a.load(f)
              g = a.load(g)
              g.wrapS = g.wrapT = window.THREE.RepeatWrapping
              e.magFilter = f.magFilter = window.THREE.LinearFilter
              e.minFilter = f.minFilter = window.THREE.LinearFilter
              // e.anisotropy = c.capabilities.getMaxAnisotropy();
              // f.anisotropy = c.capabilities.getMaxAnisotropy();
              var h = new window.THREE.ShaderMaterial({
                uniforms: {
                  effectFactor: { type: 'f', value: d },
                  dispFactor: { type: 'f', value: 0 },
                  texture: { type: 't', value: e },
                  texture2: { type: 't', value: f },
                  disp: { type: 't', value: g }
                },
                vertexShader:
                  '\n        varying vec2 vUv;\n        void main() {\n          vUv = uv;\n          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n        }\n    ',
                fragmentShader:
                  '\n        varying vec2 vUv;\n\n        uniform sampler2D texture;\n        uniform sampler2D texture2;\n        uniform sampler2D disp;\n\n        // uniform float time;\n        // uniform float _rot;\n        uniform float dispFactor;\n        uniform float effectFactor;\n\n        // vec2 rotate(vec2 v, float a) {\n        //  float s = sin(a);\n        //  float c = cos(a);\n        //  mat2 m = mat2(c, -s, s, c);\n        //  return m * v;\n        // }\n\n        void main() {\n\n            vec2 uv = vUv;\n\n            // uv -= 0.5;\n            // vec2 rotUV = rotate(uv, _rot);\n            // uv += 0.5;\n\n            vec4 disp = texture2D(disp, uv);\n\n            vec2 distortedPosition = vec2(uv.x + dispFactor * (disp.r*effectFactor), uv.y);\n            vec2 distortedPosition2 = vec2(uv.x - (1.0 - dispFactor) * (disp.r*effectFactor), uv.y);\n\n            vec4 _texture = texture2D(texture, distortedPosition);\n            vec4 _texture2 = texture2D(texture2, distortedPosition2);\n\n            vec4 finalTexture = mix(_texture, _texture2, dispFactor);\n\n            gl_FragColor = finalTexture;\n            // gl_FragColor = disp;\n        }\n    ',
                transparent: !0,
                opacity: 1
              })
              d = new window.THREE.PlaneBufferGeometry(
                b.offsetWidth,
                b.offsetHeight,
                1
              )
              d = new window.THREE.Mesh(d, h)
              n.add(d)
              d = function() {
                var a = 'mouseenter',
                  c = 'mouseleave'
                t() && ((a = 'touchstart'), (c = 'touchend'))
                b.addEventListener(a, function(a) {
                  window.TweenMax.to(h.uniforms.dispFactor, l, {
                    value: 1,
                    ease: k
                  })
                })
                b.addEventListener(c, function(a) {
                  window.TweenMax.to(h.uniforms.dispFactor, m, {
                    value: 0,
                    ease: k
                  })
                })
              }
              r && d()
              window.addEventListener('resize', function(a) {
                c.setSize(b.offsetWidth, b.offsetHeight)
              })
              this.next = function() {
                window.TweenMax.to(h.uniforms.dispFactor, l, {
                  value: 1,
                  ease: k
                })
              }
              this.previous = function() {
                window.TweenMax.to(h.uniforms.dispFactor, m, {
                  value: 0,
                  ease: k
                })
              }
              var q = function() {
                requestAnimationFrame(q)
                c.render(n, p)
              }
              q()
            }
            loadScript(
              'https://cdnjs.cloudflare.com/ajax/libs/smoothscroll/1.4.10/SmoothScroll.min.js',
              () => {
                window.SmoothScroll({
                  // Время скролла 400 = 0.4 секунды
                  animationTime: 800,
                  // Размер шага в пикселях
                  stepSize: 75,

                  // Дополнительные настройки:

                  // Ускорение
                  accelerationDelta: 30,
                  // Максимальное ускорение
                  accelerationMax: 2,

                  // Поддержка клавиатуры
                  keyboardSupport: true,
                  // Шаг скролла стрелками на клавиатуре в пикселях
                  arrowScroll: 50,

                  // Pulse (less tweakable)
                  // ratio of "tail" to "acceleration"
                  pulseAlgorithm: true,
                  pulseScale: 4,
                  pulseNormalize: 1,

                  // Поддержка тачпада
                  touchpadSupport: true
                })
                setIsLoaded(true)
              }
            )
          }
        )
    )
  })
  if (!isLoaded) return <div />
  return (
    <Layout
      pageTitle="prototypes bureau"
      description="SMM design, ICO full package design, landing page development, crypto app creation that will be covered from top by event branding."
      language={language}
      locales={config.languages}
    >
      <main className="page_index">
        <Navbar />
        <Preface />
        <Portfolio />
        <WeAlso />
        {/* <Team /> */}
        <Footer />
        {/* <ContactForm target="js-popup-hire-us" /> */}
      </main>
    </Layout>
  )
}

export default IndexPage
