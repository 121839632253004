import React from 'react'
import './project.less'

const ProjectItem = props => {
  const { name, description, link, isActiveLink } = props

  return isActiveLink ? (
    <a href={link} className="projectItem" target="_blank">
      <div className="name">{name}</div>
      <div className="description">{description}</div>
    </a>
  ) : (
    <span
      href={link}
      className={`projectItem ${isActiveLink ? '' : 'isNonActiveLink'}`}
    >
      <div className="name">{name}</div>
      <div className="description">{description}</div>
    </span>
  )
}

export default ProjectItem
