import React, { useEffect } from 'react'

const OutlineButton = ({ children, setStartAnim, id, className = '' }) => {
  useEffect(() => {
    const watch = document.getElementById(id)

    function obCallback(payload) {
      if (watch !== null) {
        if (payload[0].isIntersecting === true) {
          setStartAnim(true)
        }
      }
    }

    const ob = new IntersectionObserver(obCallback)
    if (watch) {
      ob.observe(watch)
    }
  })
  return <div className={className ? className : ''}>{children}</div>
}

export default OutlineButton
