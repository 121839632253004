import React from 'react'
import { Fade } from 'react-reveal'
import './section_weAlso.less'

const WeAlso = () => (
  <section className="section sectionWeAlso">
    <div className="sectionContent wrapper_section-content">
      <Fade left>
        <img className="star" src="/icons/images/star.webp" alt="star" />
      </Fade>
      <Fade left>
        <div className="description">
          <div>Well, we also draw and animate </div>
          <div>
            <span className="orangeText">stickers</span>
            <span> for telegram, make </span>
          </div>
          <span className="orangeText">presentations</span>
          <span>, and</span>
          <span className="orangeText"> videos</span>
        </div>
      </Fade>
      <Fade right>
        <img
          src="/icons/images/weAlsoBg.webp"
          width="100%"
          className="weAlsoBg"
          alt="photo"
        />
        <img
          src="/icons/images/weAlsoBgMobile.webp"
          width="100%"
          className="weAlsoBgMobile"
          alt="photo"
        />
      </Fade>
    </div>
  </section>
)

export default WeAlso
