import React, { useEffect, useState } from 'react'
import Fade from 'react-reveal/Fade'
import { Spinner } from '../../Spinner'
// import Lottie from 'react-lottie'
import './section_preface.less'
import line from '../../animations/line.json'

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: line,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const Preface = () => {
  const [stop, setStop] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setStop(false)
    }, 1900)
  }, [setStop])

  return (
    <section className="section section_preface">
      <div className="wrapper_section-content" id="preface-content">
        <div className="section__content">
          <div className="titleWrapper">
            <Fade delay={300} bottom>
              <div className="section__title section__title1">
                conversion of
              </div>
            </Fade>
          </div>
          <div className="titleWrapper">
            <Fade delay={600} bottom>
              <div className="section__title">ideas to profitable</div>
            </Fade>
          </div>
          <div className="titleWrapper">
            <Fade delay={900} bottom>
              <div className="section__title3">
                <div className="section__title3-1">business. </div>
                <div className="section__wrapperTextAnimation">
                  <div className="section__textAnimation">Rapidly.</div>
                  <div className="animationWrapper">
                    {/*
                    <Lottie
                      isStopped={stop}
                      options={defaultOptions}
                      // height={131}
                      width="100%"
                    /> */}
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className="listWrapperMobile">
          <h2 className="section__list-title">We are hepling with:</h2>
          <div className="section__list">
            <p className="section__list-item">Idea</p>
            <p className="section__list-item">Design</p>
            <p className="section__list-item">Development</p>
            <p className="section__list-item">Marketing & PR</p>
          </div>
        </div>
        <div className="section__bottomPanel">
          <div className="section__bottomPanel-bg1">
            <img src={'/icons/images/bottomPanel-left.svg'} alt="background" />
          </div>
          <div className="listWrapper">
            <h2 className="section__list-title">We are hepling with:</h2>
            <div className="section__list">
              <p className="section__list-item">Idea</p>
              <p className="section__list-item">Design</p>
              <p className="section__list-item">Development</p>
              <p className="section__list-item">Marketing & PR</p>
            </div>
          </div>
          <Spinner />
        </div>
        <img
          className="section__arrowImage"
          src={'/icons/images/arrowImage.svg'}
          alt="background"
        />
        <img
          className="section__bg2"
          src={'/icons/images/prefaceBg2.svg'}
          alt=""
        />
      </div>
    </section>
  )
}

export default Preface
