import React from 'react'

import './spinner.css'

export const Spinner = () => {
  return (
    <a href="mailto:info@prttps.com" target="_blank">
      <div className="spinner__wrapper">
        <div className="spinner-circle">
          <svg
            className="spinnerSvg"
            width="140"
            height="140"
            viewBox="0 0 140 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="70" cy="70" r="70" fill="#FE4747" />
            <path
              d="M118.276 76.7988L118.398 75.6745L122.318 76.1L122.78 71.8242L118.86 71.3986L118.981 70.2801L127.463 71.2009L127.342 72.3194L123.695 71.9235L123.233 76.1993L126.879 76.5952L126.757 77.7195L118.276 76.7988Z"
              fill="white"
            />
            <path
              d="M117.06 82.6477L117.362 81.564L125.578 83.8617L125.276 84.9454L117.06 82.6477Z"
              fill="white"
            />
            <path
              d="M118.086 90.2863L118.907 88.458L115.76 87.0407L116.223 86.0089L124.002 89.5127L122.845 92.0893C122.452 92.966 121.95 93.5497 121.34 93.8405C120.729 94.1348 120.042 94.1103 119.279 93.767C118.795 93.5488 118.432 93.2268 118.191 92.8011C117.948 92.3789 117.842 91.8943 117.874 91.3472L113.751 91.6883L113.687 91.6594L114.182 90.5582L118.086 90.2863ZM119.746 88.8358L119.038 90.4128C118.809 90.9225 118.759 91.3863 118.889 91.8044C119.016 92.226 119.301 92.5363 119.742 92.7353C120.223 92.9518 120.657 92.9735 121.043 92.8004C121.427 92.6308 121.743 92.2759 121.989 91.7358L122.695 90.1642L119.746 88.8358Z"
              fill="white"
            />
            <path
              d="M112.481 101.15L114.598 98.1193L112.122 96.3836L109.663 99.9045L108.909 99.3764L112.013 94.9332L118.999 99.8308L115.928 104.226L115.17 103.695L117.596 100.222L115.351 98.6474L113.234 101.678L112.481 101.15Z"
              fill="white"
            />
            <path
              d="M104.306 115.887L100.695 111.346C100.198 110.714 99.9865 110.04 100.062 109.326C100.134 108.613 100.487 107.964 101.12 107.379L101.346 107.184C102.076 106.602 102.814 106.335 103.561 106.383C104.308 106.431 104.96 106.799 105.515 107.485L109.133 112.034L108.263 112.729L104.667 108.207C104.283 107.724 103.851 107.454 103.371 107.397C102.894 107.342 102.41 107.512 101.918 107.904C101.421 108.301 101.146 108.737 101.095 109.213C101.041 109.691 101.206 110.172 101.59 110.655L105.19 115.181L104.306 115.887Z"
              fill="white"
            />
            <path
              d="M95.4652 116.002C96.4497 115.807 97.2299 115.788 97.806 115.944C98.3803 116.105 98.8029 116.45 99.0738 116.979C99.3804 117.577 99.3955 118.193 99.1193 118.827C98.8414 119.466 98.3203 119.983 97.5562 120.376C97.0352 120.644 96.5198 120.782 96.0101 120.789C95.4969 120.799 95.0382 120.683 94.6341 120.443C94.2266 120.205 93.915 119.875 93.6993 119.455L94.7049 118.937C94.9401 119.396 95.2705 119.681 95.6959 119.791C96.1231 119.905 96.6024 119.825 97.1338 119.552C97.627 119.298 97.9564 118.99 98.122 118.628C98.2859 118.272 98.2689 117.9 98.0711 117.514C97.9125 117.205 97.6477 117.01 97.2767 116.928C96.904 116.852 96.3579 116.887 95.6383 117.033C94.9153 117.181 94.3214 117.239 93.8566 117.206C93.3901 117.178 92.9987 117.065 92.6824 116.867C92.3626 116.672 92.1038 116.381 91.906 115.995C91.5905 115.38 91.5771 114.763 91.8657 114.144C92.1561 113.529 92.7025 113.015 93.5048 112.602C94.0258 112.334 94.5629 112.183 95.116 112.149C95.6709 112.118 96.1608 112.213 96.5856 112.434C97.007 112.656 97.3308 112.988 97.5571 113.43L96.5516 113.947C96.3164 113.488 95.9617 113.212 95.4875 113.118C95.0117 113.029 94.4907 113.13 93.9246 113.421C93.3966 113.693 93.0472 114.009 92.8764 114.369C92.7055 114.729 92.7155 115.095 92.9062 115.467C93.0968 115.839 93.3741 116.059 93.738 116.127C94.1036 116.198 94.6794 116.156 95.4652 116.002Z"
              fill="white"
            />
            <path
              d="M83.4804 121.619C83.5503 121.952 83.5026 122.247 83.3374 122.506C83.1684 122.765 82.908 122.931 82.5564 123.006C82.2086 123.079 81.9037 123.034 81.6416 122.869C81.3804 122.709 81.2124 122.451 81.1376 122.095L81.093 121.883C81.0231 121.551 81.0712 121.257 81.2372 121.002C81.4032 120.748 81.6659 120.583 82.0251 120.507C82.3806 120.432 82.6874 120.477 82.9457 120.642C83.2009 120.812 83.3639 121.065 83.4346 121.401L83.4804 121.619Z"
              fill="white"
            />
            <path
              d="M64.9712 118.537L66.0996 118.611L65.8417 122.546L70.1332 122.828L70.3911 118.893L71.5137 118.967L70.9556 127.48L69.833 127.406L70.073 123.746L65.7815 123.464L65.5415 127.124L64.4131 127.05L64.9712 118.537Z"
              fill="white"
            />
            <path
              d="M59.1717 117.554L60.2669 117.812L58.3217 126.118L57.2265 125.861L59.1717 117.554Z"
              fill="white"
            />
            <path
              d="M51.5944 118.882L53.4538 119.629L54.7365 116.425L55.7858 116.846L52.6149 124.767L49.9944 123.714C49.1027 123.355 48.499 122.877 48.1832 122.278C47.8637 121.679 47.8593 120.991 48.17 120.215C48.3675 119.721 48.6736 119.346 49.0885 119.087C49.4997 118.827 49.979 118.702 50.5264 118.711L50.0129 114.602L50.0391 114.537L51.1591 114.987L51.5944 118.882ZM53.1119 120.483L51.508 119.838C50.9897 119.63 50.5246 119.599 50.1127 119.745C49.6971 119.889 49.3994 120.187 49.2193 120.636C49.0233 121.126 49.0199 121.56 49.2089 121.939C49.3943 122.317 49.7618 122.618 50.3113 122.843L51.9097 123.485L53.1119 120.483Z"
              fill="white"
            />
            <path
              d="M40.5651 113.723L43.6755 115.722L45.3057 113.175L41.6925 110.853L42.1886 110.079L46.7482 113.009L42.1481 120.194L37.6378 117.295L38.137 116.516L41.7008 118.806L43.1795 116.496L40.0691 114.498L40.5651 113.723Z"
              fill="white"
            />
            <path
              d="M25.6091 106.22L29.9807 102.407C30.5897 101.881 31.2522 101.64 31.9684 101.684C32.682 101.725 33.3442 102.05 33.955 102.657L34.1599 102.875C34.7723 103.58 35.0705 104.307 35.0546 105.057C35.0387 105.807 34.7006 106.475 34.0405 107.061L29.6601 110.882L28.9298 110.041L33.2837 106.244C33.7488 105.838 33.9995 105.394 34.0356 104.912C34.0688 104.432 33.8791 103.954 33.4666 103.48C33.049 102.999 32.6019 102.744 32.1254 102.714C31.6464 102.68 31.1743 102.867 30.7092 103.272L26.3509 107.074L25.6091 106.22Z"
              fill="white"
            />
            <path
              d="M25.1085 97.3531C25.342 98.33 25.3923 99.1098 25.2595 99.6926C25.1214 100.274 24.7941 100.711 24.2776 101.004C23.6932 101.335 23.0791 101.376 22.4353 101.126C21.7861 100.875 21.2501 100.375 20.8273 99.6265C20.539 99.1164 20.381 98.6065 20.3532 98.0968C20.3235 97.5838 20.4204 97.1201 20.644 96.7059C20.8657 96.2883 21.1821 95.9628 21.5932 95.7297L22.1496 96.7142C21.7011 96.9686 21.4301 97.3109 21.3367 97.7411C21.2399 98.1732 21.3385 98.6494 21.6326 99.1698C21.9055 99.6527 22.2258 99.9695 22.5936 100.12C22.9561 100.269 23.3259 100.237 23.7031 100.023C24.0055 99.8515 24.1899 99.5784 24.2565 99.2039C24.3177 98.8279 24.261 98.2829 24.0864 97.5691C23.9099 96.8519 23.829 96.2601 23.8436 95.7937C23.853 95.3259 23.9498 94.9296 24.1343 94.6049C24.3168 94.2769 24.5966 94.0059 24.9738 93.792C25.5752 93.4508 26.1903 93.4118 26.819 93.6749C27.4444 93.94 27.9791 94.4653 28.423 95.2508C28.7113 95.761 28.8835 96.292 28.9397 96.8439C28.9925 97.3978 28.9172 97.8919 28.7139 98.326C28.5086 98.7568 28.1902 99.0946 27.7587 99.3393L27.2023 98.3548C27.6508 98.1004 27.9127 97.7341 27.9879 97.2558C28.0578 96.7761 27.9361 96.259 27.6229 95.7047C27.3307 95.1878 27.0015 94.8513 26.6352 94.6954C26.2689 94.5394 25.9039 94.5645 25.5404 94.7707C25.1768 94.9769 24.9685 95.2634 24.9156 95.6303C24.8593 95.999 24.9236 96.5733 25.1085 97.3531Z"
              fill="white"
            />
            <path
              d="M17.7042 80.4353C17.3682 80.4862 17.076 80.4219 16.8277 80.2422C16.5787 80.0587 16.4274 79.7893 16.3737 79.434C16.3207 79.0825 16.3837 78.7806 16.5629 78.5282C16.7382 78.2764 17.0054 78.1233 17.3646 78.0689L17.5789 78.0364C17.9149 77.9854 18.2052 78.0501 18.4497 78.2303C18.6942 78.4105 18.8439 78.6822 18.8987 79.0452C18.953 79.4044 18.8902 79.7083 18.7105 79.9568C18.5263 80.202 18.2642 80.3504 17.9244 80.4019L17.7042 80.4353Z"
              fill="white"
            />
            <path
              d="M21.8177 62.2968L21.6782 63.4191L17.7646 62.9354L17.2341 67.2033L21.1477 67.687L21.0089 68.8034L12.5421 67.757L12.6808 66.6406L16.3211 67.0905L16.8516 62.8226L13.2114 62.3727L13.3509 61.2505L21.8177 62.2968Z"
              fill="white"
            />
            <path
              d="M23.0972 56.5242L22.7789 57.6033L14.5987 55.1811L14.9171 54.1021L23.0972 56.5242Z"
              fill="white"
            />
            <path
              d="M22.2515 48.6959L21.3972 50.5086L24.5169 51.9843L24.0348 53.0072L16.3229 49.3593L17.5269 46.8045C17.9365 45.9353 18.449 45.361 19.0643 45.0817C19.6813 44.7988 20.3676 44.8361 21.1233 45.1935C21.6035 45.4207 21.9603 45.7494 22.1937 46.1795C22.4288 46.6062 22.5255 47.0927 22.4839 47.6391L26.6116 47.3748L26.6751 47.4049L26.1606 48.4967L22.2515 48.6959ZM20.5656 50.1152L21.3025 48.5516C21.5406 48.0463 21.599 47.5835 21.4776 47.1631C21.3578 46.7391 21.079 46.4236 20.6412 46.2165C20.1645 45.991 19.7315 45.9612 19.3424 46.1271C18.9549 46.2896 18.6329 46.6385 18.3762 47.1739L17.6418 48.7322L20.5656 50.1152Z"
              fill="white"
            />
            <path
              d="M28.0303 37.9496L25.858 40.9414L28.3015 42.7221L30.8249 39.2466L31.5684 39.7884L28.3839 44.1742L21.4893 39.1496L24.6393 34.8113L25.3875 35.3565L22.8985 38.7845L25.1146 40.3996L27.2869 37.4078L28.0303 37.9496Z"
              fill="white"
            />
            <path
              d="M36.2398 23.507L39.7835 28.0996C40.272 28.739 40.4734 29.4156 40.3876 30.1293C40.3049 30.8406 39.9429 31.4841 39.3016 32.0598L39.0725 32.252C38.3344 32.8237 37.5922 33.0798 36.8458 33.0205C36.0994 32.9612 35.4535 32.5845 34.908 31.8903L31.3571 27.2885L32.2373 26.6068L35.7667 31.1808C36.1437 31.6695 36.5716 31.9458 37.0503 32.0099C37.5266 32.0708 38.0133 31.9088 38.5105 31.5237C39.0139 31.1338 39.2946 30.7015 39.3526 30.2268C39.4137 29.7496 39.2557 29.2667 38.8787 28.7781L35.3457 24.1995L36.2398 23.507Z"
              fill="white"
            />
            <path
              d="M45.2468 23.3988C44.2589 23.5752 43.4784 23.5801 42.9054 23.4133C42.3341 23.2414 41.9179 22.8888 41.6568 22.3555C41.3614 21.7521 41.3577 21.1359 41.6456 20.5069C41.9354 19.8728 42.4659 19.3662 43.2372 18.9872C43.7631 18.7288 44.2809 18.6006 44.7907 18.6025C45.304 18.6027 45.7605 18.7266 46.16 18.9742C46.5631 19.2201 46.8685 19.5553 47.0764 19.9798L46.0614 20.4786C45.8347 20.0154 45.5097 19.7247 45.0864 19.6063C44.6613 19.4843 44.1806 19.5551 43.6442 19.8187C43.1464 20.0633 42.8113 20.365 42.6391 20.7239C42.4686 21.0775 42.4787 21.449 42.6693 21.8384C42.8222 22.1507 43.0833 22.351 43.4528 22.4393C43.824 22.5224 44.3707 22.4975 45.0928 22.3646C45.8185 22.23 46.4134 22.1836 46.8775 22.2255C47.3433 22.262 47.7326 22.382 48.0452 22.5852C48.3613 22.7868 48.6147 23.0823 48.8054 23.4717C49.1094 24.0927 49.1114 24.7098 48.8113 25.3229C48.5096 25.9325 47.9538 26.4363 47.1439 26.8342C46.618 27.0926 46.0783 27.2338 45.5246 27.2578C44.9692 27.2782 44.4812 27.1742 44.0605 26.9456C43.6433 26.7154 43.3257 26.3775 43.1076 25.9319L44.1225 25.4332C44.3492 25.8963 44.6988 26.1794 45.1711 26.2824C45.6452 26.3803 46.168 26.2888 46.7394 26.008C47.2723 25.7461 47.6275 25.4367 47.805 25.0796C47.9825 24.7226 47.9794 24.3564 47.7956 23.981C47.6118 23.6056 47.3386 23.3807 46.9761 23.3064C46.6118 23.2286 46.0354 23.2594 45.2468 23.3988Z"
              fill="white"
            />
            <path
              d="M62.4483 16.9894C62.417 16.651 62.4982 16.3629 62.6919 16.1252C62.8895 15.8871 63.1673 15.7515 63.5251 15.7182C63.8791 15.6853 64.1768 15.7655 64.4183 15.9588C64.6595 16.1482 64.7968 16.4238 64.8303 16.7855L64.8503 17.0014C64.8817 17.3398 64.8003 17.6259 64.6062 17.8597C64.4121 18.0935 64.1322 18.2274 63.7666 18.2614C63.4049 18.2951 63.1052 18.215 62.8676 18.0214C62.6335 17.8234 62.5006 17.5533 62.4689 17.2111L62.4483 16.9894Z"
              fill="white"
            />
            <path
              d="M80.5324 22.1672L79.4209 21.9586L80.1416 18.0817L75.9147 17.2884L75.194 21.1653L74.0883 20.9578L75.6474 12.5703L76.7531 12.7778L76.0828 16.384L80.3098 17.1772L80.9801 13.571L82.0916 13.7796L80.5324 22.1672Z"
              fill="white"
            />
            <path
              d="M86.1267 23.7808L85.0692 23.3969L87.9714 15.3744L89.0288 15.7584L86.1267 23.7808Z"
              fill="white"
            />
            <path
              d="M93.6663 23.2531L91.9053 22.2968L90.263 25.3321L89.2692 24.7925L93.3289 17.2891L95.8107 18.6368C96.6552 19.0954 97.1999 19.6401 97.4448 20.271C97.6932 20.9037 97.6185 21.5876 97.2207 22.3229C96.9679 22.7901 96.6206 23.1283 96.1789 23.3373C95.7405 23.5482 95.2501 23.6175 94.7074 23.5451L94.7453 27.6862L94.7118 27.748L93.6511 27.172L93.6663 23.2531ZM92.343 21.4877L93.862 22.3125C94.3529 22.5791 94.8113 22.6636 95.2372 22.5659C95.6665 22.4701 95.9964 22.2092 96.2269 21.7832C96.4779 21.3194 96.5312 20.8883 96.387 20.49C96.2462 20.0935 95.9159 19.7518 95.3959 19.465L93.8821 18.643L92.343 21.4877Z"
              fill="white"
            />
            <path
              d="M104.048 29.6014L101.184 27.2637L99.2764 29.6095L102.604 32.3251L102.023 33.0389L97.8245 29.612L103.207 22.9927L107.36 26.3826L106.776 27.1009L103.494 24.4223L101.764 26.5499L104.629 28.8877L104.048 29.6014Z"
              fill="white"
            />
            <path
              d="M118.223 38.9019L113.437 42.1797C112.771 42.6311 112.085 42.7933 111.379 42.6663C110.675 42.5424 110.055 42.1432 109.519 41.4686L109.341 41.2284C108.815 40.4573 108.603 39.7002 108.706 38.9573C108.809 38.2144 109.223 37.5903 109.947 37.085L114.742 33.8006L115.37 34.7202L110.603 37.9847C110.094 38.3335 109.794 38.7453 109.701 39.2203C109.613 39.693 109.745 40.1891 110.1 40.7086C110.459 41.2346 110.873 41.5403 111.343 41.6257C111.815 41.7144 112.305 41.5844 112.815 41.2356L117.586 37.9678L118.223 38.9019Z"
              fill="white"
            />
            <path
              d="M117.682 47.69C117.562 46.6929 117.601 45.9126 117.8 45.3489C118.004 44.7874 118.379 44.3908 118.926 44.1591C119.545 43.897 120.159 43.9271 120.77 44.2494C121.387 44.5738 121.862 45.1319 122.196 45.9236C122.424 46.4634 122.522 46.988 122.492 47.4974C122.462 48.0105 122.313 48.4599 122.043 48.8456C121.775 49.235 121.423 49.5219 120.988 49.7063L120.548 48.6645C121.023 48.4633 121.332 48.1544 121.474 47.7378C121.619 47.3197 121.576 46.8354 121.344 46.2848C121.128 45.7738 120.846 45.4223 120.498 45.2304C120.155 45.0406 119.784 45.0303 119.384 45.1995C119.064 45.3351 118.85 45.5851 118.741 45.9495C118.637 46.316 118.631 46.8638 118.722 47.5929C118.815 48.3256 118.828 48.9227 118.76 49.3842C118.697 49.8479 118.555 50.2303 118.335 50.5316C118.116 50.8366 117.807 51.0736 117.407 51.2428C116.771 51.5125 116.155 51.4806 115.561 51.1471C114.97 50.812 114.499 50.2288 114.148 49.3976C113.92 48.8578 113.81 48.3105 113.817 47.7558C113.828 47.1996 113.96 46.7175 114.212 46.3096C114.465 45.9053 114.82 45.6063 115.277 45.4128L115.717 46.4546C115.242 46.6558 114.94 46.9896 114.81 47.456C114.686 47.9245 114.747 48.452 114.995 49.0386C115.226 49.5856 115.514 49.9576 115.86 50.1546C116.206 50.3516 116.572 50.3686 116.957 50.2055C117.342 50.0424 117.581 49.7818 117.676 49.4235C117.774 49.0636 117.776 48.4858 117.682 47.69Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="spinner-logo">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.1667 10.4167L43.75 25.0001M43.75 25.0001L29.1667 39.5834M43.75 25.0001H6.25"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
    </a>
  )
}
