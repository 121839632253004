import React from 'react'
import './navbar.less'

class Navbar extends React.Component {
  render() {
    return (
      <nav className="section navbar">
        <div className="wrapper_section-content">
          <img
            className="navbar__logo"
            src={'/icons/logo.svg'}
            alt="prototypes bureau logo"
          />
        </div>
      </nav>
    )
  }
}

export default Navbar
