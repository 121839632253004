import React, { useState } from 'react'
import OutlineButton from '../../button/button'
import Fade from 'react-reveal/Fade'
import { Spinner } from '../../Spinner'
import './footer.less'

const Footer = () => {
  const [startAnim, setStartAnim] = useState(false)

  return (
    <footer className="section footer">
      <div className="wrapper_section-content">
        <div className="topBlock">
          <div className="titleWrapper">
            <Fade bottom>
              <div className="title">Let’s Talk</div>
            </Fade>
          </div>
          <Spinner />
        </div>
        <div className="bottomBlock">
          <div>
            <img src={'/icons/logo.svg'} className="footerLogo " alt="logo" />
            <div className="copyright">
              Copyright © {new Date().getFullYear()} all rights reserved
            </div>
          </div>
          <a
            className="footerBtn"
            href="mailto:info@prttps.com"
            target="_blank"
          >
            <div id="footerButton" className="buttonText">
              info@prttps.com
            </div>
            <OutlineButton id="footerButton" setStartAnim={setStartAnim}>
              <svg
                width="164"
                height="64"
                viewBox="0 0 164 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={startAnim ? 'outline' : ''}
                  d="M163 32C163 36.0453 160.903 40.007 156.878 43.7209C152.852 47.4357 146.971 50.827 139.619 53.6958C124.923 59.4311 104.552 63 82 63C59.4484 63 39.0773 59.4311 24.3808 53.6958C17.0294 50.827 11.1483 47.4357 7.12207 43.7209C3.09685 40.007 1 36.0453 1 32C1 27.9547 3.09685 23.993 7.12207 20.2791C11.1483 16.5643 17.0294 13.173 24.3808 10.3042C39.0773 4.56893 59.4484 1 82 1C104.552 1 124.923 4.56893 139.619 10.3042C146.971 13.173 152.852 16.5643 156.878 20.2791C160.903 23.993 163 27.9547 163 32Z"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
            </OutlineButton>
          </a>
          {/* <div className="XXXXServices">XXXX services</div> */}
        </div>
        <img className="bg1" src={'/icons/images/bg1.svg'} alt="" />
        <img className="bg2" src={'/icons/images/bg2.svg'} alt="" />
        <img
          className="bg1Mobile"
          src={'/icons/images/footerMobile1.svg'}
          alt=""
        />
        <img
          className="bg2Mobile"
          src={'/icons/images/footerMobile2.svg'}
          alt=""
        />
      </div>
    </footer>
  )
}

export default Footer
